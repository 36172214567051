import styled from 'styled-components';

export const ContactContainer = styled.div`
	position:fixed;
	//z-index:999;
	width:100%;
	height:100%;
	background: #353535;
	//display:grid;
	align-items:center;
	justify-content:center;
	top:0;
	left:0;
	color:white;
	padding-bottom:130px;
	overflow-y:auto;  
`;

export const SubTitle = styled.div`

margin-top:10px;
font-size: 15px;
display:flex;
justify-content:center;	
font-family: 'Oxygen', sans-serif;
background: #353535;
text-align:center;
color:white;

}
    
    
`;

export const SubTitleContainer  = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
@media screen and (max-width:500px) {
    width:80%;
}
`;



