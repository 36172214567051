import React from 'react'
import ContactSection from '../components/ContactSection';

function ContactPage () {
    return(
      <div className="contact">
        <ContactSection>isSidebar={false} </ContactSection>   
      </div>
    )
  };

  export default ContactPage;