import { FaBars } from "react-icons/fa";
import React from "react";
import {LogoRoute,MobileIcon, Nav, NavbarContainer,LogoBg} from './NavbarElements'
//import { Button } from "../MixingSection/MixingElements";
//import { FiRefreshCw } from "react-icons/fi";
//https://www.youtube.com/watch?v=Nl54MJDR2p8

import Logo from '../../images/smoothi-logo-white-on-clear.svg'


const Navbar = ({toggle, isLanding}) => {
  return (
    <Nav>
      <NavbarContainer>
        <LogoRoute to="/"> 
          <LogoBg  src={Logo} />
        </LogoRoute>
        <MobileIcon onClick={toggle} isLanding={isLanding}>
          <FaBars/>
        </MobileIcon> 
      </NavbarContainer>
    </Nav>
  )
}
//<StyledSlot></StyledSlot>
export default Navbar