import { useEffect, useState, useRef } from 'react';

import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { TitleContainer } from "../componentsElements";
import {Message, SubmitContainer, StyledInputMessage, FormContainer, CSPageContainer, StyledForm, StyledInput, StyledButton} from './CustomerSupportElements'

import emailjs from '@emailjs/browser';

function FormSuccess() {
  return (
    <Message>Success!</Message>
  )
}

function Form({form, name, setName, email, setEmail, message, setMessage, clickedSubmit, error, errorMessage}) {
  
  return (
    <div>
      <FormContainer>
          <StyledForm ref={form} >
            <StyledInput name="name" value={name} type="text" placeholder={'Name'} onChange={
              (event)=>{setName(event.target.value)}
            }/>
            <StyledInput name="email" value={email} type="email" placeholder={'Email'} onChange={
              (event)=>{setEmail(event.target.value)}
            }/>
            <StyledInputMessage name="message" value={message} type="message" placeholder={'Message'} onChange={
              (event)=>{setMessage(event.target.value)}
              }/>
          </StyledForm> 
        </FormContainer>
        <SubmitContainer>
          <StyledButton onClick={clickedSubmit}>Submit</StyledButton>
        </SubmitContainer>
        <Message>
        {error ? errorMessage:''}
        </Message>
    </div>
  )
}

function CustomerSupportSection() {

  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [formSuccesful, setFormSuccesful] = useState(false)
  const [formError, setFormError] = useState(false)

  const form = useRef();

  const serviceID = process.env.REACT_APP_SERVICEID
  const publicKey = process.env.REACT_APP_PUBLICKEY
  const templateID = process.env.REACT_APP_TEMPLATEID

  useEffect(()=>{
    emailjs.init({
      publicKey: publicKey,
    });
  }, [publicKey])
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = () => {
    if (name.length < 4) {
      setError(true)
      setErrorMessage('Name Too Short')
    }
    else if (!validateEmail(email)) {
      setError(true)
      setErrorMessage('Email not Valid')
    }
    else if (message.length < 10) {
      setError(true)
      setErrorMessage('Message too short')
    }
    else {
      setError(false)
      setErrorMessage('')
      return true
    }
    return false
  }

  const submitForm = () => {
    emailjs
      .sendForm(serviceID, templateID, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          setFormSuccesful(true)
          setFormError(false)
        },
        (error) => {
          setFormError(true)
        },
      );
  }

  const clickedSubmit = () => {
    if (validate()) {
      submitForm()
    }
  }

  return (
    <div>
      <CSPageContainer >
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle} isLanding={true} />
        <TitleContainer>Customer Support</TitleContainer>
        {formSuccesful ? <FormSuccess/>:<Form form={form} name={name} setName={setName}
          email={email} setEmail={setEmail}
          message={message} setMessage={setMessage}
          clickedSubmit={clickedSubmit} 
          error={error} errorMessage={errorMessage}/>}
        {formError ? 'Error sending form':''}
      </CSPageContainer>
      <Footer isSidebar={false}> </Footer>
    </div>
  )
}

export default CustomerSupportSection;