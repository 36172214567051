import styled from 'styled-components';

export const TitleContainer = styled.div`
   

color:white;
margin-top:200px;
display:flex;
justify-content:center;
align-content:center;
font-family: 'Oxygen';
font-size:25px;
cursor:pointer;
text-align:center;

@media screen and (max-width:500px) {
		font-size:20px;
	}
    

    
`;

