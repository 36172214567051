import { useSearchParams } from "react-router-dom"
import { SmoothiContainer, SmoothiContent, EmailActionText} from "../components/AppComponents/appElements"
import { ImageBg } from '../components/AppComponents/appElements'
import Navbar from "../components/Navbar"

function SubscriptionPage() {
  const [searchParams] = useSearchParams()
  const canceled = searchParams.get("canceled") === 'true'
  const success = searchParams.get("success") === 'true'

  return(
    <div>
      <Navbar/>
      <SmoothiContainer>
        <ImageBg style={{backgroundColor:"#353535"}} />
        <SmoothiContent>
          <EmailActionText>
          {canceled ? 'Subscription canceled.':''}
          {success ? 'Thanks for subscribing!':''}
          </EmailActionText>
        </SmoothiContent>
      </SmoothiContainer>
    </div>
  )
}

export default SubscriptionPage