import React from 'react';
import { useState } from 'react';
import { ContactContainer,SubTitle} from './ContactElements'
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import {TitleContainer} from "../componentsElements";

function ContactSection() {

const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <ContactContainer >
        <Sidebar isOpen={isOpen} toggle={toggle} isSidebar={true}/>
        <Navbar toggle={toggle} isLanding={true} />
        <TitleContainer>Contact Us</TitleContainer>
        <SubTitle>Want to get in touch? Email us at hello@smoothi.io.</SubTitle>
      </ContactContainer>
      <Footer isSidebar={true} />
    </div>
   
  )

}

export default ContactSection;