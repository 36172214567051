import styled from 'styled-components';

export const DownloadPageContainer = styled.div`
   
position:fixed;
//z-index:999;
width:100%;
height:100%;
background: #353535;
//display:grid;
align-items:center;
top:0;
left:0;
color:white;
overflow-y:auto;
padding-bottom:100px;

    

    
`;
export const DownloadPageWrapper = styled.div`
`;

export const AppStoreWrapper = styled.div`
display:flex;
justify-content:center;
margin-top:50px;
`;

export const AppStoreContainer = styled.div`
   

width:450px;
display:flex;
justify-content:space-between;


@media screen and (max-width:500px) {
		display:grid;
        justify-content:center;
        gap:10px;
	}

    @media screen and (max-width:350px) {
		display:grid;
        justify-content:center;
        gap:10px;
	}
    
`;




