import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useMutation } from '@apollo/client';
import { FiArrowRightCircle } from 'react-icons/fi'
import Navbar from '../components/Navbar';
import { ImageBg, SmoothiContainer, SmoothiContent, EmailActionText } from '../components/AppComponents/appElements'; 
import { BtnCreate } from "../components/LogInSection/logInElements";

const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token){
      success
      errors
    }
  }
`;

function Activation({token}) {
  const [verifyAccountFunction, { data, loading, error }] = useMutation(VERIFY_ACCOUNT, { errorPolicy: 'all' })

  useEffect(() => {
    verifyAccountFunction({ variables: { token: token } })
  },[verifyAccountFunction, token])

  if (error) {
    return (
      <div>An error occured.
        </div>
    )
  }
  else if (loading) {
    return (
      <div>
        loading...
      </div>
    )
  }
  else if (data) {
    if (data.verifyAccount.success){
      return (
        <div>Success! Your account has been verified. </div>
      )
    }
    else if (!data.success && data.verifyAccount.errors) {
      return (
        <div>{data.verifyAccount.errors.nonFieldErrors[0].message} </div>
      )
    }
  }
}

function ActivatePage() {
  let params = useParams();
  const client = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    cache: new InMemoryCache()
  });
  const appURL = 'https://app.smoothi.io'

  return (
    <ApolloProvider client={client}>
      <Navbar/>
      <SmoothiContainer>
      <ImageBg style={{backgroundColor:"#353535"}} />
        <SmoothiContent>
          <EmailActionText>
          <Activation token={params.token}/>
          </EmailActionText>
          <Link to={appURL} style={{ textDecoration:"none" }}>
            <BtnCreate style={{ color:"white", cursor: 'pointer' }}>
              Go to web app <FiArrowRightCircle style={{ color:"white",fontSize: "38px", margin: "7px", cursor: 'pointer' }} /> 
            </BtnCreate>
          </Link>
        </SmoothiContent>
      </SmoothiContainer>
    </ApolloProvider>
  )
}

export default ActivatePage