import styled from "styled-components";
import {TwitterWithCircle} from '@styled-icons/entypo-social/TwitterWithCircle'
import {LinkedinWithCircle} from '@styled-icons/entypo-social/LinkedinWithCircle'
import {InstagramWithCircle} from'@styled-icons/entypo-social/InstagramWithCircle'
import {Link as LinkT} from 'react-router-dom';

export const FooterWrapper = styled.div`
display: ${({ isSidebar }) => (isSidebar ? 'none' : 'block')};
font:Inter;
font-size:10px;
font-weight:bold;
position:absolute;
left:0;
bottom:0;
right:0;
padding-bottom:50px;
color:white;
background:#353535;

`;

export const FooterSlot = styled.div`
flex: 1;
align-content: center;
justify-content: center;
width: 100%;
height: 1px;
background-color: #fff;
//position: absolute;
top: 0;
z-index: 11;
opacity:0.15;

`;

export const SmallTextContainer = styled.div`
margin-top:15px;
margin-left:35px;
margin-right:30px;
display:flex;
justify-content:space-between;

@media screen and (max-width: 500px) {
  margin-left:25px;
}



@media screen and (max-width: 350px) {
  
  margin-right:10px;
  font-size:8.5px;
}

`;


export const EmailContainer = styled.div`
font-family: 'Arial';
`;

export const TermsContainer = styled.div`
width:180px;
display:flex;
justify-content:space-between;

@media screen and (max-width: 350px) {
  width:120px;
}
}

`;

export const Terms = styled.div`

`;

export const SocialMediaContainer = styled.div`

display:flex;
justify-content:flex-end;


`;

export const SocialMediaWrapper = styled.div`
display: ${({ isSidebar }) => (isSidebar ? 'flex' : 'none')};
margin-top:15px;
margin-left:30px;
margin-right:30px;
margin-bottom:15px;
//display:flex;
justify-content:space-between;
width:80px;



`;

export const Twitter = styled(TwitterWithCircle)`

color: white;
width:22px;
`;

export const Linkedin = styled(LinkedinWithCircle)`

color: white;
width:22px;
`;

export const IG = styled(InstagramWithCircle)`

color: white;
width:22px;
`;

export const TermsRoute = styled(LinkT)`
  
  color: white;
  
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;

    color: white;
  }
`