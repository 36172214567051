import styled from 'styled-components'

export const LogoBg = styled.img`
margin-bottom:10px;

    
`

export const Input = styled.input`
 


  border: 2.25px solid black;
  border-color:#393939;
  outline:none;
  font-family: Inter;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #393939;
    font-size: 13px;
    font-Weight: normal;
    opacity:80%;
    
  }
  :-ms-input-placeholder {
     color: #393939;
     font-size: 13px;
     font-Weight: normal;
     opacity:80%;
  }
  background: ${({ isSelected }) => (isSelected ? 'rgb(256, 256, 256, 1)' : 'rgb(0, 0, 0, 0)')};
  padding: 10px 20px;
  
  margin: 0.75em 1.5em;
  width: 170px;
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align:center;
  

  @media screen and (max-width: 300px) {
		width: 160px;
	}
	
`

export const BirthdayInput = styled.input`
 
background: ${({ isSelected }) => (isSelected ? 'none' : 'rgb(0, 0, 0, 0)')}; 
border:none;
outline:none;
height:50px;
width:105px;
margin-top:-17px;
margin-left:18px;
//background:none;
opacity:100%;
position:absolute;
cursor:pointer;
font-size: 13.5px;
font-family: Inter;
color: #393939;
    font-size: 13px;
    font-Weight: normal;
    opacity:80%;



`
export const BirthdayButtonWrapper= styled.div`
display:flex;
  flex-direction: column;
  align-items:center;
  text-align:center;
`

export const BirthdayText2 = styled.div`

font-size: 12px;
font-family:Inter;
margin-top:-10px;


`

export const BirthdayButtonContainer = styled.div`

  //color: ${({ isSelected }) => (isSelected ? '#EFEBE5' : '#393939')};
 
  border: 2.25px solid black;
  border-color:black;
  outline:none;
  font-family: Inter;
  border-radius: 30px;
  font-weight: 400;
  opacity:80%;
  font-size: 14px;
  font-Weight: normal;
  border-opacity:40%;
  height:15px;
 
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #393939;
    font-size: 13px;
    font-Weight: normal;
    opacity:80%;
    
  }
  :-ms-input-placeholder {
     color: #393939;
     font-size: 13px;
     font-Weight: normal;
     opacity:80%;
  }
  padding: 10px 20px;
  
  margin: 0.0em 1.5em;
  width: 170px;
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align:center;

  @media screen and (max-width: 300px) {
		width: 160px;
	}
	
`


export const ButtonBdayPhone = styled.button`
  border: 2.25px solid black;
  border-color:#393939;
  outline:none;
  font-family: Inter;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 400;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #393939;
    font-size: 13px;
    font-Weight: normal;
    opacity:80%;
  }
  :-ms-input-placeholder {
     color: #393939;
     font-size: 13px;
     font-Weight: normal;
     opacity:80%;
  }
  padding: 10px 20px;

  margin: 0.75em 1.5em;
  width: 170px;
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align:center;
`

export const PButton = styled.p`
  color: #A9A9A9; 
  font-size: 11px;
  height:10px;
  margin-top:5px;
`

export const Button = styled.button`
  display: inline-block;
  background: pink;
  color: palevioletred;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
  //display: block;
`;

export const SignInButton = styled.button`
color:white;
border: 2px #D34F1D;
border-radius: 30px;
font-size: 15px;
font-weight: 400;
font-family: Inter;
::placeholder,
::-webkit-input-placeholder {
  color: black;
  font-size: 13px;
  font-Weight: normal;
  font-family: Inter;
}
:-ms-input-placeholder {
   color: black;
   font-size: 13px;
   font-Weight: normal;
   font-family: Inter;
}

background: #D34F1D;
padding: 10px 20px;

margin: 0.75em 1.5em;
width: 210px;
display:flex;
flex-direction: column;
align-items:center;
text-align:center;

`;

export const CreateAccountButton = styled.button`
color:white;
border: 2px #D34F1D;
border-radius: 30px;
font-size: 15px;
font-weight: 400;
font-family: Inter;
::placeholder,
::-webkit-input-placeholder {
  color: black;
  font-size: 13px;
  font-Weight: normal;
  font-family: Inter;
}
:-ms-input-placeholder {
   color: black;
   font-size: 13px;
   font-Weight: normal;
   font-family: Inter;
}

background: #D34F1D;
padding: 10px 20px;

margin: 1.75em 1.5em;
width: 100px;
display:flex;
flex-direction: column;
align-items:center;
text-align:center;

`;

export const ResetPassButton = styled.button`
color:white;
border: 2px #D34F1D;
border-radius: 30px;
font-size: 13px;
font-weight: 400;
font-family: Inter;
::placeholder,
::-webkit-input-placeholder {
  color: black;
  font-size: 12px;
  font-Weight: normal;
  font-family: Inter;
}
:-ms-input-placeholder {
   color: black;
   font-size: 12px;
   font-Weight: normal;
   font-family: Inter;
}

background: #D34F1D;
padding: 10px 20px;


width: 150px;
display:flex;
flex-direction: column;
align-items:center;
text-align:center;

`;





export const BtnBday = styled.ul`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 2px solid black;
  border-radius: 30px;
  font-size: 12px;
  font-Weight: bold;

  background: ${({ isSelected }) => (isSelected ? 'rgb(256, 256, 256, 1)' : 'rgb(0, 0, 0, 0)')};
  padding: 10px 8px;
  
  margin-bottom:10px;
	margin-top:5px;
	margin-left:12px;
  margin-right:12px;
  width: 80px;
  align-items: center;
  text-align: center;
  height: 20px;

  @media screen and (max-width: 400px) {
		margin-left:8px;
    margin-right:8px;
	}

  @media screen and (max-width: 300px) {
		margin-left:2px;
    margin-right:2px;
	}
`

export const BdayContainer = styled.div`
  display:flex;
  flex-direction: row;
`

export const AcceptContainer = styled.div`
  //display:flex;
  //flex-direction: row;
`

export const LoginHeader = styled.img`
color: #D34F1D;
text-align: center;
font-size: 32px;
font-family: Larken-Bold;
font-weight:bold;
margin-bottom:20px;
letter-spacing: -.5px;

@media screen and (max-width: 300px) {
		width:90%;
	}
w


`

export const TermsContainer =styled.div`
color: #000;
text-align: center;
font-size: 11px;
font-family: Inter;
font-style: normal;
font-weight: 400;
line-height: normal;
width:300px;
`



export const BtnTerms = styled.button`
  color:white;
border: 2px #D34F1D;
border-radius: 30px;
font-size: 12px;
font-weight: 400;
font-family: Inter;
::placeholder,
::-webkit-input-placeholder {
  color: black;
  font-size: 13px;
  font-Weight: normal;
  font-family: Inter;
}
:-ms-input-placeholder {
   color: black;
   font-size: 13px;
   font-Weight: normal;
   font-family: Inter;
}

background: #D34F1D;
padding: 10px 20px;

margin: 0.75em 1.5em;
width: 120px;
display:flex;
flex-direction: column;
align-items:center;
text-align:center;
`

export const BtnTerms2 = styled.div`


z-index: 2;
color: #727272;
font-family: 'Inter';
font-size: 10.7px;

margin-top:4px;
font-weight: 600;

&:hover{
  text-decoration:underline;
	transition:0.3s ease-out;
}
`

export const SelectionContainer = styled.div`
  z-index: 2;
	//width:400px;
	display: flex;
	flex-direction: column;
	text-align: center;
  align-items: center;
	
`

export const DateLi = styled.li`
  display: inline-block;
  //align-items: center;
`

export const CredentialsContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items:center;
`

export const LogInContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items:center;
  margin-top:100px;
`

export const BtnCreate = styled.div`
  z-index: 2;
  color: #D34F1D;
  font-family: Inter;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
 
  font-Weight: bold;
  padding-bottom:.3rem;


 
`

export const BtnReturn = styled.div`
color:white;
border: 2px #D34F1D;
border-radius: 30px;
font-size: 15px;
font-weight: 400;
font-family: Inter;
::placeholder,
::-webkit-input-placeholder {
  color: black;
  font-size: 13px;
  font-Weight: normal;
  font-family: Inter;
}
:-ms-input-placeholder {
   color: black;
   font-size: 13px;
   font-Weight: normal;
   font-family: Inter;
}

background: #D34F1D;
padding: 10px 20px;

margin: 0.75em 1.5em;
width: 160px;
display:flex;
flex-direction: column;
align-items:center;
text-align:center;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

`;
export const BtnSignInContainer = styled.div`


  flex-direction: row;
  justify-content:center;
  

`

export const BtnCreateAccountContainer = styled.div`


  flex-direction: row;
  justify-content:center;

`

export const CreateAccountHeader = styled.div`

color:#393939;
padding-Bottom:1rem;
text-Align:center;
font-Weight: 600;
padding-top:3rem;


`

export const CreateAccountText= styled.div`

color:#393939;
padding-Bottom:1rem;
text-Align:center;
font-Weight: bold;
padding-top:1rem;
font-size:14px;


`

export const CreatePageHeader = styled.div`

display: flex;
flex-direction: column;


`


export const BtnForgot = styled.div`


z-index: 2;
color: #727272;
font-family: 'Inter';
font-size: 10.7px;

margin-top:-12px;
font-weight: 600;

&:hover{
  text-decoration:underline;
	transition:0.3s ease-out;
}
`

export const StyledSlotLogIn = styled.div` 
	flex: 1;
	align-content: center;
	justify-content: center;
	width: 250px;
	height: 1px;
	background-color: #fff;
	top: 0;
	z-index: 11;
	opacity:0.15;
	margin-bottom:20px;
	margin-top:-7px;
	margin-left:0px;
`

export const BirthdayText = styled.div`
font-size:12px;
font-weight:500;

`

export const Square = styled.div`
background-color:#EFEBE5;
color:#EFEBE5;
z-index: 999;
opacity:100%;
width:20px;
height:20px;
margin-right:-255px;
margin-top:-5.5px;

`

