import React from 'react'
import JoinUsSection from '../components/JoinUsSection';




function JoinUsPage () {
  
    
    
    return(
      <div className="joinus">
       <JoinUsSection> isSidebar={false} </JoinUsSection>
           
      </div>
    )
  };

  export default JoinUsPage;