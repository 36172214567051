import styled from 'styled-components';

export const CSPageContainer = styled.div`
   
position:fixed;
//z-index:999;
width:100%;
height:100%;
background: #353535;
//display:grid;
align-items:center;
top:0;
left:0;
color:white;
padding-bottom:100px;
overflow-y:auto;
    

    
`;

export const StyledForm = styled.form`
  //background-color: #f4f4f4;
  //border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
`

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${props => props.invalid ? 'red' : 'white'};
`

export const StyledInput = styled.input`

  width: 200px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 11px;
  background-color:#353535;
  color: white;
  font-weight: bold;
  font-size:0.75rem;
  font-family: 'Oxygen';
  margin-left:20px;
  outline: none;

  
`
export const StyledInputMessage = styled.input`
margin-top:20px;
  width:90%;
  height:140px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 11px;
  background-color:#353535;
  color: white;
  font-weight: bold;
  font-size:0.75rem;
  font-family: 'Oxygen';
  margin-left:20px;
  margin-right:30px;
  padding-bottom: 110px;
 
  outline: none;

`

export const StyledButton = styled.button`
  background-color: #white;
  color: black;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height:40px;
  width:100px;
  font-weight: bold;
  font-size:0.9rem;
  font-family: 'Oxygen';
  text-align:top;
  
`

export const StyledAlert = styled.div`
  padding: 10px;
  background-color: #f44336;
  color: white;
  margin-top: 10px;
  border-radius: 5px;
`

export const FormContainer = styled.div`
   

color:white;
margin-top:10px;
display:flex;
justify-content:center;
font-family: 'Oxygen';
font-size:25px;
    

    
`;
export const SubmitContainer = styled.div`
   

color:white;

display:flex;
justify-content:center;
font-family: 'Oxygen';
font-size:25px;

    
`;

export const Message = styled.div`
   
color:grey;
margin-top:40px;
display:flex;
justify-content:center;
font-family: 'Oxygen';
font-size:18px;

    
`;