import { useState } from 'react';

import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import HeroSection from '../components/HeroSection';
//import {HeroBg, ImageBg} from '../components/HeroSection/HeroElements';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function LandingPage () {
  
  const [isOpen, setIsOpen] = useState(false)
  const [imageNumber, setImageNumber] = useState(getRandomInt(1, 3))
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  
  return(
    <div className="landing">
      <Sidebar isOpen={isOpen} toggle={toggle} isSidebar={true}/>
			<Navbar toggle={toggle} isLanding={true} />
      <HeroSection imageNumber={imageNumber} setImageNumber={setImageNumber}/>
    </div>
  )
}

export default LandingPage