import React from 'react';
import { useState } from 'react';
import { JoinUsContainer,SubTitle} from './JoinUsElements'
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import {TitleContainer} from "../componentsElements";

function JoinUsSection() {

const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
   <JoinUsContainer >
    <Sidebar isOpen={isOpen} toggle={toggle} isSidebar={true}/>
    <Navbar toggle={toggle} isLanding={true}/>
    <TitleContainer>Join Us</TitleContainer>
    <SubTitle>Email us at jobs@smoothi.io</SubTitle>
    
   </JoinUsContainer>
   <Footer isSidebar={true}>
    </Footer>
   </div>
   
  )

}

export default JoinUsSection;