import React from 'react'
import CustomerSupportSection from '../components/CustomerSupportSection';

function CustomerSupportPage () {
  
    return(
      <div className="customerSupportPage">
        <CustomerSupportSection></CustomerSupportSection>    
      </div>
    )
  };

  export default CustomerSupportPage;