import React from 'react';
import {FooterWrapper, SocialMediaContainer, SocialMediaWrapper, Twitter, Linkedin, IG, FooterSlot,SmallTextContainer,EmailContainer,TermsContainer} from './FooterElements';

const Footer = ({isSidebar}) => {

  return (
    <FooterWrapper>
      <SocialMediaContainer  >
        <SocialMediaWrapper isSidebar={isSidebar} >
          <a href="https://www.twitter.com/smoothi_io">
            <Twitter> </Twitter>
            </a> 
            <a href="https://www.linkedin.com/company/cuetessa">
            <Linkedin>  </Linkedin>
            </a>
            <a href="https://www.instagram.com/smoothi.io/">
            <IG>  </IG>
            </a>
        </SocialMediaWrapper>
      </SocialMediaContainer>
      <FooterSlot></FooterSlot>
      <SmallTextContainer>
        <EmailContainer>
          hello@smoothi.io
        </EmailContainer>
        <TermsContainer> 
        </TermsContainer>
      </SmallTextContainer>
    </FooterWrapper>
  )
}
//<Terms>
//<TermsRoute to="/privacyPage">Privacy Policy</TermsRoute>
////</Terms>
//<Terms>
//<TermsRoute to="/termsPage">Terms & Conditions</TermsRoute>

//</Terms>

export default Footer;