import React from 'react'
import DownloadSection from '../components/DownloadSection';




function DownloadPage () {
  
    
    
    return(
      <div className="download">
       <DownloadSection> </DownloadSection>
           
      </div>
    )
  };

  export default DownloadPage;