import React from 'react';
import { useState } from 'react';
import {AppStoreWrapper, AppStoreContainer, DownloadPageContainer} from './DownloadElements'
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
import {TitleContainer} from "../componentsElements";

function DownloadSection() {

const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
   <DownloadPageContainer >
    <Sidebar isOpen={isOpen} toggle={toggle} isSidebar={true}/>
    <Navbar toggle={toggle} isLanding={true} />
    <TitleContainer>Download App - Coming Soon</TitleContainer>
    
    <AppStoreWrapper>
    
    
    <AppStoreContainer >
    
    
    <GooglePlayButton
        
        theme={"dark"}
        className={"custom-style"}
        height={"70px"}
        width={"200px"}
        
        
        
      />
      <AppStoreButton
        
        theme={"dark"}
        className={"custom-style"}
        height={"70px"}
        width={"200px"}
      />
      </AppStoreContainer>
      </AppStoreWrapper>
      
      
   
    
   </DownloadPageContainer>

   <Footer> isSidebar={false} </Footer>
   
   </div>
  )

}

export default DownloadSection;